var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.proDetail
    ? _c("div", { staticClass: "p-pro-detail" }, [
        _c("div", { staticClass: "p-pro-detail__header" }, [
          _c("div", { staticClass: "p-pro-detail__header-bg" }, [
            _c("img", { attrs: { src: _vm.headerImg, alt: "" } }),
          ]),
          _c(
            "div",
            { staticClass: "p-pro-detail__header-avatar" },
            [
              _c("ProAvatar", {
                attrs: {
                  src: _vm.proDetail.image_url,
                  alt: _vm.proDetail.name,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "p-pro-detail__header-favorite" },
            [
              _c("FavoriteButton", {
                attrs: {
                  id: _vm.proDetail.id,
                  state: _vm.proDetail.is_favorite,
                },
                on: { onClickFavorite: _vm.onClickFavorite },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "p-pro-detail__profile" }, [
          _c("h1", { staticClass: "p-pro-detail__profile-header" }, [
            _c("span", { staticClass: "p-pro-detail__profile-header-name" }, [
              _c(
                "span",
                { staticClass: "p-pro-detail__profile-header-icon" },
                [
                  _c("Icon", {
                    attrs: {
                      name: "pro",
                      color: "secondary01",
                      width: "24",
                      height: "24",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" " + _vm._s(_vm.proDetail.name) + " "),
            ]),
            _c("span", { staticClass: "p-pro-detail__profile-header-ruby" }, [
              _vm._v(" " + _vm._s(_vm.proDetail.name_alphabetical) + " "),
            ]),
          ]),
          _vm.proDetail.overview
            ? _c("p", {
                staticClass: "p-pro-detail__profile-career",
                domProps: { innerHTML: _vm._s(_vm.proDetail.overview) },
              })
            : _vm._e(),
          _vm.proDetail.tags.length
            ? _c(
                "ul",
                { staticClass: "p-pro-detail__profile-tags" },
                _vm._l(_vm.proDetail.tags, function (tag, index) {
                  return _c("li", { key: index }, [
                    _vm._v(" #" + _vm._s(tag.name) + " "),
                  ])
                }),
                0
              )
            : _vm._e(),
        ]),
        _vm.proDetail.short_message
          ? _c("div", {
              staticClass: "p-pro-detail__comment",
              domProps: { innerHTML: _vm._s(_vm.proDetail.short_message) },
            })
          : _vm._e(),
        _vm.proDetail.notice
          ? _c("div", { staticClass: "p-pro-detail__notice" }, [
              _c("h2", { staticClass: "p-pro-detail-title" }, [
                _vm._v(_vm._s(_vm.WORDS.NOTICE)),
              ]),
              _c("div", {
                staticClass: "p-pro-detail__notice-content",
                domProps: { innerHTML: _vm._s(_vm.proDetail.notice) },
              }),
            ])
          : _vm._e(),
        _vm.preVideos
          ? _c("div", { staticClass: "p-pro-detail__video" }, [
              _c("h2", { staticClass: "p-pro-detail-title" }, [
                _vm._v(_vm._s(_vm.WORDS.VIDEO)),
              ]),
              _c(
                "div",
                { staticClass: "p-pro-detail__inner" },
                [
                  _vm._l(_vm.preVideos, function (item, index) {
                    return _c("ProDetailVideo", {
                      key: index,
                      attrs: { content: item, index: index },
                    })
                  }),
                  _vm._l(_vm.storedVideos, function (item, index) {
                    return _c("ProDetailStoredContent", {
                      key: "storedVideos" + index,
                      attrs: {
                        type: "video",
                        content: item,
                        "button-label": _vm.WORDS.PRO_DETAIL.MORE_VIDEO,
                      },
                    })
                  }),
                ],
                2
              ),
            ])
          : _vm._e(),
        _vm.preEvents
          ? _c("div", { staticClass: "p-pro-detail__event" }, [
              _c("h2", { staticClass: "p-pro-detail-title" }, [
                _vm._v(_vm._s(_vm.WORDS.PRO_DETAIL.TITLE_EVENT)),
              ]),
              _c(
                "div",
                { staticClass: "p-pro-detail__inner" },
                [
                  _vm._l(_vm.preEvents, function (item, index) {
                    return _c("EventCard", {
                      key: index,
                      staticClass: "u-mb24",
                      attrs: { content: item },
                    })
                  }),
                  _vm._l(_vm.storedEvents, function (item, index) {
                    return _c("ProDetailStoredContent", {
                      key: "storedEvents" + index,
                      attrs: {
                        type: "event",
                        content: item,
                        "button-label": _vm.WORDS.PRO_DETAIL.MORE_PRO_COFFEE,
                      },
                    })
                  }),
                ],
                2
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }