<template>
  <div v-if="proDetail" class="p-pro-detail">
    <div class="p-pro-detail__header">
      <div class="p-pro-detail__header-bg">
        <img :src="headerImg" alt="" />
      </div>
      <div class="p-pro-detail__header-avatar">
        <ProAvatar :src="proDetail.image_url" :alt="proDetail.name" />
      </div>
      <div class="p-pro-detail__header-favorite">
        <FavoriteButton
          :id="proDetail.id"
          :state="proDetail.is_favorite"
          @onClickFavorite="onClickFavorite" />
      </div>
    </div>

    <div class="p-pro-detail__profile">
      <h1 class="p-pro-detail__profile-header">
        <span class="p-pro-detail__profile-header-name">
          <span class="p-pro-detail__profile-header-icon">
            <Icon name="pro" color="secondary01" width="24" height="24" />
          </span>
          {{ proDetail.name }}
        </span>
        <span class="p-pro-detail__profile-header-ruby">
          {{ proDetail.name_alphabetical }}
        </span>
      </h1>

      <p
        v-if="proDetail.overview"
        class="p-pro-detail__profile-career"
        v-html="proDetail.overview" />

      <ul v-if="proDetail.tags.length" class="p-pro-detail__profile-tags">
        <li v-for="(tag, index) in proDetail.tags" :key="index">
          #{{ tag.name }}
        </li>
      </ul>
    </div>

    <div
      v-if="proDetail.short_message"
      class="p-pro-detail__comment"
      v-html="proDetail.short_message" />

    <div v-if="proDetail.notice" class="p-pro-detail__notice">
      <h2 class="p-pro-detail-title">{{ WORDS.NOTICE }}</h2>
      <div class="p-pro-detail__notice-content" v-html="proDetail.notice" />
    </div>

    <div v-if="preVideos" class="p-pro-detail__video">
      <h2 class="p-pro-detail-title">{{ WORDS.VIDEO }}</h2>
      <div class="p-pro-detail__inner">
        <ProDetailVideo
          v-for="(item, index) in preVideos"
          :key="index"
          :content="item"
          :index="index" />
        <ProDetailStoredContent
          v-for="(item, index) in storedVideos"
          :key="'storedVideos' + index"
          type="video"
          :content="item"
          :button-label="WORDS.PRO_DETAIL.MORE_VIDEO" />
      </div>
    </div>

    <!-- <div v-if="preProducts" class="p-pro-detail__product">
      <h2 class="p-pro-detail-title">
        {{ WORDS.PRO_DETAIL.TITLE_PRO_COFFEE }}
      </h2>
      <div class="p-pro-detail__inner">
        <ProDetailOriginalCoffee
          v-for="(item, index) in preProducts"
          :key="index"
          :content="item" />
        <ProDetailStoredContent
          v-for="(item, index) in storedProducts"
          :key="'storedProducts' + index"
          type="product"
          :content="item"
          :button-label="WORDS.PRO_DETAIL.MORE_EVENT" />
      </div>
    </div> -->

    <div v-if="preEvents" class="p-pro-detail__event">
      <h2 class="p-pro-detail-title">{{ WORDS.PRO_DETAIL.TITLE_EVENT }}</h2>
      <div class="p-pro-detail__inner">
        <EventCard
          class="u-mb24"
          v-for="(item, index) in preEvents"
          :key="index"
          :content="item" />
        <ProDetailStoredContent
          v-for="(item, index) in storedEvents"
          :key="'storedEvents' + index"
          type="event"
          :content="item"
          :button-label="WORDS.PRO_DETAIL.MORE_PRO_COFFEE" />
      </div>
    </div>
  </div>
</template>

<script>
import { slice, chunk } from 'lodash';
import { SystemDialogMessage } from '@/constants/enums';
import { WORDS } from '@/constants/words';
import { defineComponent, ref, computed } from '@vue/composition-api';

export default defineComponent({
  components: {
    ProDetailStoredContent: () =>
      import('@/components/pro/ProDetailStoredContents.vue'),

    ProDetailVideo: () => import('@/components/pro/ProDetailVideo.vue')
    // ProDetailOriginalCoffee: () =>
    //   import('@/components/pro/ProDetailOriginalCoffee.vue')
  },

  setup: (props, context) => {
    const proDetail = ref(undefined);

    const preVideosLength = 3;
    const preProductsLength = 5;
    const preEventsLength = 2;

    const videos = ref(undefined);
    const preVideos = ref(undefined);
    const storedVideos = ref(undefined);

    const products = ref(undefined);
    const preProducts = ref(undefined);
    const storedProducts = ref(undefined);

    const events = ref(undefined);
    const preEvents = ref(undefined);
    const storedEvents = ref(undefined);

    const proId = computed(() => Number(context.root.$route.params.id));
    const headerImgId = computed(() => Number(proDetail.value?.header) || 0);
    const headerImg = computed(() => {
      const src = {
        0: 'header_type_a',
        1: 'header_type_b',
        2: 'header_type_c'
      };
      return `/assets/img/pro/${src[headerImgId.value]}.jpg`;
    });

    const sleep = (ms = 200) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const storeContent = () => {
      storedVideos.value = slice(videos.value, 1);
      storedProducts.value = slice(products.value, 1);
      storedEvents.value = slice(events.value, 1);
    };

    const onClickFavorite = (data) => {
      proDetail.value = data;
    };

    const fetchProDetail = async () => {
      try {
        context.root.$_loading_start();
        const { data } = await context.root
          .$repositories('barista')
          .getPro(proId.value);
        proDetail.value = data;

        videos.value = chunk(proDetail.value?.movies, preVideosLength);
        products.value = chunk(proDetail.value?.products, preProductsLength);
        events.value = chunk(proDetail.value?.events, preEventsLength);

        preVideos.value = videos.value[0];
        preProducts.value = products.value[0];
        preEvents.value = events.value[0];
        await sleep();
        storeContent();
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    fetchProDetail();

    return {
      WORDS,

      proId,
      proDetail,
      headerImg,

      videos,
      products,
      events,

      preVideos,
      preEvents,
      preProducts,

      preVideosLength,
      preProductsLength,
      preEventsLength,

      storedVideos,
      storedEvents,
      storedProducts,

      onClickFavorite
    };
  }
});
</script>
